import FlexLayout from './FlexLayout';


export default function Lessons() {
    return (
        <FlexLayout style={{ fontFamily: "Lato,Helvetica,Segoe UI,Arial,sans serif" }}>
            <div className="Text">

                <p>Ed provides lessons to guitarists that are interested in learning to play jazz, or looking to improve their jazz skills.
                </p>

                <p>Learning to play any instrument takes dedication and concentrated effort.
                The role of a good teacher in the learning process is to help you make efficient use of your time and energy.
                A good teacher can assess where you are on the path to total mastery, and help you prioritize what issues you need to address to progress efficiently, and effectively.
                </p>
                <p>Email me (edrowland@edrowland.com) if you are interested in taking lessons.</p>
            </div>
        </FlexLayout>
    );
}
