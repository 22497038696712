import FlexLayout from './FlexLayout';


export default function Main() {
    return (
        <FlexLayout style={{ fontFamily: "Lato,Helvetica,Segoe UI,Arial,sans serif" }}>
            <div className="Text">
                
                <p>Ed Rowland specializes in providing light cocktail jazz music for restaurants, and events in Ottawa. Ed’s music is perfect as occasional music for
                    corporate events, cocktail parties, receptions, dinners, birthdays, weddings, art shows or restaurants.</p>
                <p>Ed Rowland has played jazz guitar in and around Ottawa for almost twenty years. He studied with Juno Award-winning jazz guitarist, Mike Rudd, and is well known among Ottawa jazz musicians as the founder of the Ottawa Jazz Collective, and as host of the OJC Open Jam held at the Avant Garde cafe for many years.</p>
                <p>Music can be provided in any of the following formats, according your budget and preferences:</p>
                <ul>
                    <li>Solo guitar with recorded backing tracks.</li>
                    <li>Jazz trio (guitar, piano, drums)</li>
                    <li>Jazz quartet (saxophone, guitar, piano, drums)</li>
                </ul>
                <p>Please email Ed at edrowland@edrowland.com if you would like a quote for an event you are planning.</p>
            </div>
        </FlexLayout>
    );
}
