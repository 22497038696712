import FlexLayout from './FlexLayout';


export default function Contacts() {
    return (
        <FlexLayout style={{ fontFamily: "Lato,Helvetica,Segoe UI,Arial,sans serif" }}>
            <div>
                <p style={{fontSize: "30px"}} >Contacts</p>
            </div>
            <div className="Text" >
                <table className="Contacts" >
                    <tbody>
                        <tr>
                            <td style={{ paddingRight: "20px" }}>
                                <p>
                                    <span className="MyIcons">
                                        alternate_email</span>
                                </p>
                            </td>
                            <td>
                                <p>
                                    edrowland@edrowland.com
                                </p>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <p>
                                    <span className="MyIcons">
                                        call
                                    </span>
                                </p>
                            </td>
                            <td>
                                <p>
                                    1 (613) 421-2622
                                </p>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <p>
                                    <span className="MyIcons">
                                        email
                                </span>
                                </p>
                            </td>
                            <td>
                                <p>
                                    1-204 Clarence St.
                                <br />
                                Ottawa, ON. K1N 5R1
                                <br />
                                Canada
                                </p>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <p>
                                    <img src="/facebook.png" style={{width: "35px", height: "35px"}} alt="Facebook"/>
                                </p>
                            </td>
                            <td>
                                <p>
                                    <a href="https://www.facebook.com/ed.rowland.140">Ed Rowland</a>
                                </p>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </FlexLayout>
    );
}
