import FlexLayout from './FlexLayout';
import AudioPlayer from './AudioPlayer';

export default function Media() {
    return (
        <FlexLayout style={{ fontFamily: "Lato,Helvetica,Segoe UI,Arial,sans serif" }}>
            <div className="Text">
                <div>
                    <h3>Standards</h3>
                    <p>So What, Miles Davis</p>
                    <AudioPlayer url="/media/SoWhat.mp3" mediaType="audio/mpeg" duration="9:02" />
                </div>
                <div>
                    <h3>Original</h3>
                    <p>Rainy Day in Port Severn, Ed Rowland</p>
                    <AudioPlayer url="/media/PortSevern.mp3" mediaType="audio/ogg" duration="5:43" />
                    <p>Blue Sky Commercial, Ed Rowland</p>
                    <AudioPlayer url="/media/BlueSky.flac" mediaType="audio/ogg" duration="5:43" />
                </div>
            </div>
        </FlexLayout>
    );
}
