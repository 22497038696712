
import styles from './FlexLayout.module.css';

export default function FlexLayout(props) {
    return (

        <table className={styles.FlexTable}>
            <colgroup>
            </colgroup>
            <tbody>
                <tr>
                    <td className={styles.FlexLeft} >

                    </td>
                    <td className={styles.FlexMid}>
                        <div className={styles.FlexDiv}>
                            {props.children}
                        </div>
                    </td>
                    <td className={styles.FlexRight}>
                    </td>
                </tr>
            </tbody>
        </table>
    );
}