import React, {Component} from 'react';

export default class AudioPlayer extends Component {

    handleOnPlay(e)
    {
        var audios = document.getElementsByTagName('audio');
        for(var i = 0, len = audios.length; i < len;i++){
            if(audios[i] !== e.target){
                audios[i].pause();
            }
        }
    }

    render() {
        return (
            <audio controls onPlay={this.handleOnPlay} controlsList="nodownload" style={{outline:"none"}}>
                <source src={this.props.url} type={this.props.mediaType}  />
                Your browser does not support the audio element.
            </audio>
            );
    }
}


