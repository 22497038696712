
import React from 'react';
import './App.css';

import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import Main from './Main';
import Lessons from './Lessons';
import Contacts from './Contacts';
import Media from './Media';
import { ThemeProvider } from '@material-ui/core/styles';

import { createMuiTheme } from '@material-ui/core/styles';

import blue from "@material-ui/core/colors/blue"



const PageNames = ["EdRowland", "Lessons", "Music", "Contact"];


function getRoutedPage() {


  var result = (new URLSearchParams(document.location.search)).get("Page");
  if (result !== undefined) {
    for (var i = 0; i < PageNames.length; ++i) {
      if (PageNames[i] === result) {
        return i;
      }
    }
  }
  return 0;
}

const primaryColor = "#EEE";
const secondaryColor = blue[900];

const theme = createMuiTheme({
  palette: {
    primary: {
      main: primaryColor,
    },
    secondary: {
      main: secondaryColor,
    },
  },
});

function TabIcon(props) {
  const { value, index, uri, ...other } = props;
  return (<img src={uri} hidden={value !== index}  className="TabIcon"  alt="" {...other} />);
}

TabPanel.propTypes = {
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}



function getBaseUrl() {
  var url = window.location.href;
  url = url.split('?')[0];
  return url;
}

function setPageUrl(value) {
  let pageName = PageNames[value];
  if (value === 0) {
    window.history.pushState({ value: 0 }, pageName, getBaseUrl());
  } else {
    window.history.pushState({ value: value }, pageName, '?Page=' + pageName);
  }
}



class App extends React.Component {
  constructor(props) {
    super(props);
    this.mounted = false;
    let v = getRoutedPage();
    if (v == null) {
      v = 0;
    }
    this.state = { value: v };
    this.value = this.state.value;
  }

  setPageUrl(value) {
    let pageName = PageNames[value];
    if (value === 0) {
      window.history.pushState({ value: value }, pageName, getBaseUrl());
    } else {
      window.history.pushState({ value: value }, pageName, '?Page=' + pageName);
    }
  }

  handleTabChange(e, newValue) {
    let currentValue = getRoutedPage();
    if (currentValue !== newValue) {
      setPageUrl(newValue);
      this.maybeSetValue(newValue);
      this.setState({ value: newValue });
    }
  }

  setStateFromSearchParams(searchParams) {
    let v = getRoutedPage();
    this.maybeSetValue(v);
  }

  maybeSetValue(value)
  {
    if (this.state !== undefined)
    {
      if (this.state.value !== value)
      {
        this.setState({value:value});
      }
    }
  }
  onPopState(e) {
    if (e.state !== undefined) {
      this.setStateFromSearchParams(window.location.search)
    }
  }


  componentDidMount() {
    if (!this.mounted) {
      this.mounted = true;
      let t = this;
      let f = function (e) {
        t.onPopState(e);
      }
      this.popEvent = f;
      window.addEventListener("popstate", f);
    }
  }

  componentWillUnmount() {
    if (this.mounted) {
      this.mounted = false;
      if (this.popEvent !== undefined) {
        window.removeEventListener("popstate", this.popEvent);
        this.popEvent = undefined;
      }
    }
  }


  render() {

    return (
      <div style={{ flexGrow: 1, backgroundColor: theme.palette.background.paper, position: "absolute", width: "100%", height: "100%", display: "flex", flexDirection: "column", flexWrap: "nowrap", }}>
        <ThemeProvider theme={theme}>

          <header style={{ flexShrink: 0 }}>
            <AppBar position="static" style={{ scrollBehaviour: "none" }}>
              <div className="TitleFrame">
                <span>
                  <span className="PageTitle">Ed Rowland </span> <span className="PageSubtitle"> Jazz Guitarist</span>
                </span>
              </div>
              <div style={{ display: "flex", width: "100%" }}>
                <div className="TabIconSpacer" >
                  <TabIcon uri="/EdThumb1.jpg" value={this.state.value} index={0} />
                  <TabIcon uri="/Lessons.png" value={this.state.value} index={1} />
                  <TabIcon uri="/gretsch.jpg" value={this.state.value} index={2} />
                  <TabIcon uri="/Contacts.jpg" value={this.state.value} index={3} />
                </div>
                <div className="AppTabBar" style={{ flexGrow: 1, alignSelf: "flex-end" }}>
                  <Tabs value={this.state.value} onChange={(e, newValue) => this.handleTabChange(e, newValue)} aria-label="Pages" variant="standard" centered={false} 
                        style={{ verticalAlign: "bottom", }}  >
                    <Tab label="Ed Rowland" {...a11yProps(0)} />
                    <Tab label="Lessons" {...a11yProps(1)} />
                    <Tab label="Music" {...a11yProps(2)} />
                    <Tab label="Contact" {...a11yProps(3)} />
                  </Tabs>
                </div>
              </div>
            </AppBar>
          </header>
          <div style={{ flexGrow: 1, overflow: "auto", minHeight: "2em" }}>
            <TabPanel value={this.state.value} index={0}>
              <Main />
            </TabPanel>
            <TabPanel value={this.state.value} index={1}>
              <Lessons />
            </TabPanel>
            <TabPanel value={this.state.value} index={2}>
              <Media />
            </TabPanel>
            <TabPanel value={this.state.value} index={3}>
              <Contacts></Contacts>
            </TabPanel>
          </div>
        </ThemeProvider>
      </div>
    );

  }
}

export default App;
